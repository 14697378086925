<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ itemData?.id ? 'Employee payment editing' : 'Adding a new employee payment'}}</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="payment_name" :class="{'p-invalid' : submitted && !itemData.description}" v-model.trim="itemData.description" autocomplete="off"/>
          <label for="payment_name">Description<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.description">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5" v-if="itemData.order_id">
        <div class="p-float-label">
          <InputNumber @input="changePercent(itemData, $event.value)"
                       :class="{'p-invalid' : submitted && itemData.order_id && !itemData.percent}"
                       id="payment_percent"
                       v-model="itemData.percent"
                       mode="decimal"
                       :min="0"
                       :max="100"
                       :minFractionDigits="2"
                       :maxFractionDigits="2"
                       autocomplete="off"/>
          <label for="payment_percent">Percent<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && itemData.order_id && !itemData.percent">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5" :class="!!itemData.order_id ? 'p-sm-6 p-md-6 p-lg-6' : 'p-sm-12 p-md-12 p-lg-12'">
        <div class="p-float-label">
<!--          <InputNumber id="payment_sum" :class="{'p-invalid' : submitted && !itemData.sum}" v-model="itemData.sum" :min="0" :useGrouping="false" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"></InputNumber>-->
          <InputNumber @input="changeValue(itemData, $event.value)"
                       :class="{'p-invalid' : submitted && !itemData.sum}"
                       id="payment_sum"
                       v-model="itemData.sum"
                       mode="decimal"
                       :min="!!itemData.order_id ? (itemData.qty < 0 ? itemData.service_sell_price * itemData.qty : 0) : null"
                       :max="!!itemData.order_id ? (itemData.qty > 0 ? itemData.service_sell_price * itemData.qty : 0) : null"
                       :minFractionDigits="2"
                       :maxFractionDigits="2"
                       autocomplete="off"/>
          <label for="payment_sum">Sum<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.sum">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <Calendar id="payment_date"
                    :showButtonBar="true"
                    @date-select="checkDateIsValid"
                    @show="toogleDropdownOverlayVisibility(true)"
                    @hide="toogleDropdownOverlayVisibility(false)"
                    :class="{'p-invalid' : (itemData.date && !dateIsValid) || (submitted && !itemData.date)}"
                    v-model="itemData.date"
                    :showTime="true"
                    :stepMinute="10"
                    :maxDate="new Date()"
                    :dateFormat="settings.dateFormat"
                    :showIcon="true"
                    autocomplete="off"/>
          <label for="payment_date">Date<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !itemData.date">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-if="itemData.date && !dateIsValid">{{ $t('Invalid date format') }}</small>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <div class="p-float-label p-input-icon-right">-->
<!--          <i class="ti-comment-alt" />-->
<!--          <Textarea id="transaction_description" :class="{'p-invalid' : submitted && !itemData.description}" v-model="itemData.description" rows="2" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="transaction_description">Description<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && !itemData.description">{{ $t('Required field') }}</small>-->
<!--      </div>-->

    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";

export default {
  mixins: [ httpMixins, overlayVisibilityMixins ],
  emits: ['close', 'update-items'],
  name: 'CashboxModal',
  props: {
    item: Object,
    visible: Boolean,
    userId: Number,
  },
  data() {
    return {
      itemData: {},
      // selectedSign: null,
      submitted: false,
      dateIsValid: false,
      // signs: [{ sign: '-',icon: 'ti-minus' }, { sign: '+', icon: 'ti-plus' }],
      settings
    }
  },
  watch: {
    item(value) {
      this.itemData = {
        ...value,
      }

      if (value.date) {
        this.itemData.date = new Date(value.date * 1000)
      } else {
        this.itemData.date = new Date()
      }

      this.checkDateIsValid()

      if (value.percent) {
        this.itemData.percent = +value.percent
      }

      if (value.sum) {
        this.itemData.sum = +value.sum
      }
      // if (value.sum || +value.sum === 0) {
      //   if (value.sum < 0) {
      //     this.itemData.sum = +value.sum.split('-')[1]
      //   } else if (value.sum === 0) {
      //     this.itemData.sum = 0
      //   } else if (value.sum > 0) {
      //     this.itemData.sum = +value.sum
      //   }
      // }

      // if (!value.id) {
      //   this.selectedSign = this.signs[0]
      // } else {
      //   if (value.sum < 0) {
      //     this.selectedSign = this.signs[0]
      //   } else {
      //     this.selectedSign = this.signs[1]
      //   }
      // }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        // this.selectedSign = null
      }
    },
  },
  methods: {
    checkDateIsValid() {
      const date = this.itemData?.date
      if (!date) return false
      this.dateIsValid = date instanceof Date && !isNaN(date)
    },
    // changeDate(date) {
    //   console.log(date)
    // },
    changePercent(payment, value) {
      if (payment.id) {
        payment.updated = true
      }

      if (value > 0) {
        payment.percent = value > 100 ? 100 : value
      } else {
        payment.percent = 0
      }
      const itemSellPrice = +payment.service_sell_price
      if (itemSellPrice) {
        payment.sum = (itemSellPrice * (payment.percent / 100)) * payment.qty
      }
    },
    changeValue(payment, value) {
      // if (payment.id) {
      //   payment.updated = true
      // }
      const itemSellPrice = +payment.service_sell_price

      if (itemSellPrice) {
        if (payment.qty > 0) {
          payment.sum = value > itemSellPrice * payment.qty ? itemSellPrice * payment.qty : value
        } else {
          payment.sum = value < itemSellPrice * payment.qty ? itemSellPrice * payment.qty : value
        }
        const percentValue = (payment.sum * 100) / (itemSellPrice * payment.qty)
        this.changePercent(payment, percentValue)
      }
    },
    async saveItem() {
      this.submitted = true
      if ((this.itemData.date && !this.dateIsValid) ||!this.itemData.description || !this.itemData.date || !this.itemData.sum || (!!this.itemData.order_id && !this.itemData.percent)) {
        return
      }

      const employeePayment = {
        userId: this.userId,
        description: this.itemData.description,
        // date: +new Date(+this.itemData.date / 1000),
        date: this.itemData.date ? +new Date(this.itemData.date / 1000) : +new Date(Date.now() / 1000),
        percent: this.itemData.order_id ? (this.itemData.percent ?? null) : null,
        sum: this.itemData.sum,
      }

      console.log(employeePayment.date)

      if (this.itemData.id) {
        employeePayment.id = this.itemData.id
        try {
          const { status, data } = await httpClient.post(`employee-payment/update`, employeePayment)
          if (status === 200 && data?.success) {
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
            this.close()
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`employee-payment/create`, employeePayment)
          if (status === 201 && data?.success) {
            this.itemData.id = data.id
            employeePayment.id = data.id
            this.itemData.isNew = true
            this.$emit('update-items', this.itemData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
            this.close()
          } else if (data?.error) {
            this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '95%', maxWidth: '460px', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '460px', marginTop: '25px'}
      } else {
        return {position: 'absolute', top: 0, maxWidth: '460px', marginTop: '50px'}
      }
    }
  },

}
</script>

<style scoped lang="scss">

</style>