<template>
  <Toolbar class="p-mb-4 p-mt-4">
    <template #start>
      <AddNewButton :label="$t('Add')" @click="addNewItem" class="p-mr-5"/>
      <div v-if="totalPaymentsSum" class="total-sum-block">Total: {{ formatCurrency(totalPaymentsSum) }}</div>
<!--        <DatesSelector :defaultDateIndex="5" class="p-mr-2" v-show="false"/>-->
    </template>
    <template #end>
      <DatesRangeCalendar :disabled="dataTableIsLoading" @change-dates-range="changeDatesRange" v-show="true"/>
    </template>
  </Toolbar>

  <Spinner v-if="user && isLoading"></Spinner>

<!--  <DesktopDataTable v-if="!isLoading && !$store.state.tabletLayout"-->
  <DesktopDataTable v-if="!isLoading"
                    class="p-mt-4"
                    tableName="Payments"
                    :dataTableIsLoading="dataTableIsLoading"
                    :itemsArrayIsEmpty="itemsArrayIsEmpty"
                    :sort="sort"
                    :showSearchInput="false"
                    :showButtons="actionButtonsCount > 0"
                    :actionButtonsCount="actionButtonsCount"
                    @sort-items="sortItems"
                    :headers="[
              {name: 'date', title: 'Date', width: '', sortable: true},
              {name: 'order', title: 'Order', width: '', sortable: false},
              {name: 'car', title: 'Car', width: '', sortable: false},
              {name: 'name', title: 'Name', width: '', sortable: true},
              {name: 'qty', title: 'Qty', width: '', sortable: true},
              {name: 'total_time', title: 'Total time', width: '', sortable: true},
              {name: 'percent', title: 'Percent', width: '', sortable: true},
              {name: 'sum', title: 'Amount', width: '', sortable: true},
          ]">
    <template v-slot:body>
      <tr v-for="(item, index) of items" :key="index"
          :class="{'updated-item': isItemUpdated(item.id)}">
        <td class="table-data">{{ formatDate(item.date) }}</td>
        <td class="table-data">
          <span v-if="item.order?.number"><router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link></span>
          <i v-else class="ti-minus"></i>
        </td>
        <td class="table-data">
          <span v-if="item.car">
              <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank" class="p-d-flex p-flex-wrap">
                <i class="ti-car p-mr-1"></i>
                <div class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</div>
                <div class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</div>
              </router-link>
          </span>
          <i v-else class="ti-minus"></i>
        </td>
        <td class="table-data">{{ item.description }}</td>
        <td class="table-data">
          <span v-if="item.qty">{{ formatDecimal(item.qty) }}</span>
          <i v-else class="ti-minus"></i>
        </td>
        <td class="table-data">
          <span v-if="item.total_time">{{ formatDecimal(item.total_time) }} {{ $t('h') }}.</span>
          <i v-else class="ti-minus"></i>
        </td>
        <td class="table-data">
          <span v-if="item.percent">{{ formatDecimal(item.percent) }}%</span>
          <i v-else class="ti-minus"></i>
        </td>
        <td class="table-data">
          <span :class="item.sum >= 0 ? 'green-color' : 'warning-color'"><span v-if="item.sum >= 0">+</span>{{ formatCurrency(item.sum) }}</span></td>
        <td v-if="actionButtonsCount > 0">
          <div class="p-d-flex">
            <EditButton v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant || $store.state.user.role === constants.userRoles.moderator"
                        :disabled="!disableEditButton && $store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin && $store.state.user.role !== constants.userRoles.accountant && $store.state.user.role !== constants.userRoles.moderator"
                        @click="editItem(item)" />
            <DeleteButton @click="confirmItemDelete(item)"
                          v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant || $store.state.user.role === constants.userRoles.moderator"/>
          </div>
        </td>
      </tr>
    </template>
  </DesktopDataTable>

  <OwnPaginator v-show="items && items.length"
                :rows="pagination.perPage"
                :currentPage="pagination.currentPage - 1"
                :totalPages="pagination.pageCount"
                :totalRecords="pagination.totalCount"
                :rowsPerPageOptions="[5,10,25,50,100,200]"
                :pageLinkSize="11">
  </OwnPaginator>

  <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

  <EmployeePaymentModal :visible="itemModal"
                        :item="item"
                        :userId="user?.id"
                        @update-items="updateItems"
                        @close="closeItemModal">
  </EmployeePaymentModal>

  <ConfirmDeleteModal
      :visible="confirmItemDeleteModal"
      @close="closeConfirmItemDeleteModal"
      @delete-item="deleteEmployeePayment">
  </ConfirmDeleteModal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import formatMixins from "@/mixins/formatMixins";
import EmployeePaymentModal from "@/pages/users/components/EmployeePaymentModal";
import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";
// import DatesSelector from "@/components/DatesSelector";

export default {
  mixins: [ httpMixins, datatableMixins, formatMixins ],
  components: { ConfirmDeleteModal, EmployeePaymentModal, DatesRangeCalendar },
  emits: ['update-items', 'updateItems'],
  props: {
    user: Object,
  },
	data() {
		return {
      // dateRange: '',
      totalPaymentsSum: null,
      fromTimestamp: null,
      toTimestamp: null,
      confirmModal: false,
      fieldsExpand: 'car,order',
      apiLink: 'employee-payment/get-active',
      sort: {
        column: 'date',
        direction: '-',
      },
      // sort: {
      //   column: 'id',
      //   direction: '-',
      // },
		}
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('employees') === -1 || route.indexOf('payments') === -1) {
        return false
      }
      // this.checkRouteQuery()
      this.loadData()
    }
  },
  mounted() {
    // if (!this.$store.state.user?.role) return false
    this.generateThisMonthRange()
    this.loadData()
  },
  methods: {
    changeDatesRange({ fromTimestamp, toTimestamp }, loadData = false) {
      this.fromTimestamp = fromTimestamp
      this.toTimestamp = toTimestamp
      if (loadData) {
        this.loadData()
      }
    },
    async deleteEmployeePayment() {
      if (!this.itemToDelete) return false

      const employeePayment = {
        id: this.itemToDelete.id,
        order_position_id: this.itemToDelete.order_position_id
      }
      try {
        const { status } = await httpClient.post(`employee-payment/delete`, employeePayment)
        if (status === 200) {
          // this.$emit('update-items')
          this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
          this.closeConfirmItemDeleteModal()
          this.loadData()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    closeConfirmModal() {
      this.confirmModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    loadData() {
      if (!this.user) return false
      if (this.$route.params.id) {
        this.checkRouteQuery()
        this.getItems()
      }
    },
    async getItems(showSpinner = false) {
      this.dataTableIsLoading = true
      this.disableEditButton = true

      if (showSpinner) {
        this.isLoading = true
      }

      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { id: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          data: {
            userId: this.$route.params.id,
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          if (data.payments_list) {
            this.items = data.payments_list
          }
          if (data.total_sum) {
            this.totalPaymentsSum = data.total_sum
          }

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.totalPaymentsSum = null
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin || this.$store.state.user.role === this.constants.userRoles.accountant  || this.$store.state.user.role === this.constants.userRoles.moderator) {
        return 2
      } else {
        return 0
      }
    },
  }
}
</script>
<style scoped lang="scss">
.total-sum-block {
  font-size: 1.4em;
  //font-weight: 400;
}

.table-data {
  height: 45px;
}
</style>